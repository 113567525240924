import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {getComplementaryColor, IniBackgroundColor} from "../../actions/backgroundColor";
import {ArrowTitle} from "../../components/shared/arrowTitle";
import {
    createPiece,
    getAllFurniture,
    getAllIngredients,
    getPiece,
    getMenu,
    exportTypeToCsv, deleteFile, getOnePiece
} from "../../utils/api/catalogue";
import {
    faChevronDown,
    faTrash,
    faPen,
    faSearch,
    faCopy,
    faFileExport, faSpinner,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import {createType, deleteType, getTypeList, updateType} from "../../utils/api/PieceType";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {TOAST_CONFIG} from "../../constants/toast";
import ConfirmModal from "../../modal/confirmModal";
import DeleteModal from "../../modal/deleteModal";
import Pagination from "../../components/pagination/pagination";
import {authorization} from "../../actions/securityAction";
import {checkUserSub} from "../../utils/api/profile";
import ChoseDirectionModal from "../../modal/choseDirectionModal";
import {Tooltip} from 'react-tooltip'
import {faTruckPlane} from "@fortawesome/free-solid-svg-icons/faTruckPlane";
import Loading from "../../components/loading/Loading";
import {BASE_PATH} from "../../constants/apiEndPoint";
import AddPieceButton from "../../components/buttons/addPieceButton";
import ModalPieceCaterer from "../../components/modal/catalog/modalPieceCaterer";
import ModalPieceStandist from "../../components/modal/catalog/modalPieceStandist";
import DeleteModalPiece from "../../components/modal/catalog/deleteModalPiece";
import ModalAddIngredient from "../../components/modal/catalog/modalAddIngredient";
import ModalAddMenu from "../../components/modal/catalog/modalAddMenu";
import ModalAddFurniture from "../../components/modal/catalog/modalAddFurniture";
import DeleteModalMenu from "../../components/modal/catalog/deleteModalMenu";
import DeleteModalIngredientOrFurniture from "../../components/modal/catalog/deleteModalIngredientOrFurniture";
import ImportPieceButton from "../../components/buttons/importPieceButton";


export default function Catalogue(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    // const {language} = useSelector(state => state.language)
    const [t] = useTranslation('common')

    const [allPiece, setAllPiece] = useState([]);
    const [paginationPieces, setPaginationPieces] = useState([]);
    const [modalPieceCaterer, setModalPieceCaterer] = useState(false);
    const [modalPieceStandist, setModalPieceStandist] = useState(false);
    const [modalDeletePiece, setModalDeletePiece] = useState(false);

    const [pieceSelected, setPieceSelected] = useState(null);
    const [pieceAutocomplete, setPieceAutocomplete] = useState({});
    const [reload, setReload] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingIsSub, setLoadingIsSub] = useState(true);
    const [loadingTypology, setLoadingTypology,] = useState(false);
    const [loadingIngredient, setLoadingIngredient] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [searchPiece, setSearchPiece] = useState([]);
    const [orderPiece, setOrderPiece] = useState({});
    const [orderIngredient, setOrderIngredient] = useState({});
    const [orderPieceType, setOrderPieceType] = useState({});
    const [orderPieceAgencement, setOrderPieceAgencement] = useState({});
    const [orderMenu, setOrderMenu] = useState({});
    const dataObj = {
        search: "",
        order: "",
        page: ""
    }

    const [allTypes, setallTypes] = useState([]);
    const [typeSelected, setTypeSelected] = useState(null);
    const [modalTypology, setModalTypology] = useState(false);
    const [modalDeleteType, setModalDeleteType] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [choseDirectModal, setChoseDirectModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState(false);
    const [participants, setParticipants] = useState(1);

    const [ingredients, setIngredients] = useState([]);
    const [modalIngredient, setModalIngredient] = useState(false);
    const [modalFurniture, setModalFurniture] = useState(false);
    const [modalMenu, setModalMenu] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState({})

    const [visibleSpace, setVisibleSpace] = useState(false)
    const [visibleM2, setVisibleM2] = useState(false)

    const [selectedMenu, setSelectedMenu] = useState({})
    const [modalDeleteIngredientOrFurniture, setModalDeleteIngredientOrFurniture] = useState(false);
    const [modalDeleteMenu, setModalDeleteMenu] = useState(false);
    const [onlyMyIngredients, setOnlyMyIngredients] = useState(true);
    const [isCreate, setIsCreate] = useState(true);
    const [isSub, setIsSub] = useState(false);
    const [csvDownload, setCsvDownload] = useState(false);
    const [search, setSearch] = useState('');


    const exportDataToCsv = async (type) => {
        setCsvDownload(true);
        const r = await exportTypeToCsv(user.token, type);
        if (r.error === 'Error while exporting'){
            toast.error('No data', TOAST_CONFIG)
            setCsvDownload(false)
            return
        }
        let url = BASE_PATH + r.data;
        let lastIndex = url.lastIndexOf("api");
        // Remplacer la dernière occurrence de "api" par "xlsx"
        let newUrl = url.slice(0, lastIndex) + url.slice(lastIndex + 3);
        window.open(newUrl)
        setCsvDownload(false);
        //on attend que le fichier soit délivré avant de le supprimer
        setTimeout(() => {
            deleteFile(user.token, r.data);
        }, 5000)
    }


    const openModalEditType = (type) => {

        if (type !== '') {
            setIsCreate(false)
        }
        setTypeSelected(type)
        setSelectedObject(type)
        setModalTypology(true)
    }

    const saveType = async (type) => {

        if (type.id) {
            await updateType(user.token, type);
            toast.success("Mis à jour", TOAST_CONFIG)
        } else {
            await createType(user.token, type);
            toast.success("Ajouté", TOAST_CONFIG)
        }
        setReload(Date.now());
        setModalShow(false);
    }

    const deleteTypeAction = async () => {
        const r = await deleteType(user.token, typeSelected.id);
        if (r.data?.status === 'error') {
            toast.error(r.data?.data, TOAST_CONFIG);
        } else {
            toast.success("Supprimé", TOAST_CONFIG)
        }
        setReload(new Date())
    }

    const openModalDeleteType = (type) => {
        setTypeSelected(type)
        setModalDeleteType(true)
    }

    const checkIfUserIsSub = async () => {
        setLoadingIsSub(true)
        const data = await checkUserSub(user.token);
        setLoadingIsSub(false)
        setIsSub(data.data.isSub);
    }

    const openModalIngredientsOrFurnitures = () => {
        if (authorization(user, 'ROLE_CATERER')) {
            setModalIngredient(true)
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            setModalFurniture(true)
        }
    }

    const openModalDeleteIngredientOrFurniture = (ingredient) => {
        if (authorization(user, 'ROLE_CATERER')) {
           setModalDeleteIngredientOrFurniture(true)
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            setModalDeleteIngredientOrFurniture(true)
        }
    }

    const openModalEditPieceCarterer = (piece) => {
        setPieceSelected(piece)
        setModalPieceCaterer(true)
    }
    const openModalEditPieceStandist = (piece) => {
        setPieceSelected(piece)
        setModalPieceStandist(true);
    }

    const selectorModal = (piece) => {

        if (authorization(user, 'ROLE_CATERER')) {
            openModalEditPieceCarterer(piece)
        }

        if (authorization(user, 'ROLE_STANDIST')) {
            openModalEditPieceStandist(piece)
        }
    }

    const openModalDeletePiece = (piece) => {
        setPieceSelected(piece)
        setModalDeletePiece(true)
    }


    const handleSearchPiece = (e) => {
        if(e.target) {
            const searchQuery = e.target.value;
            setSearch(searchQuery)
            if (searchQuery.length >= 3 || searchQuery.length === 0) {
                handleSearchPiece(searchQuery);
                setCurrentPagePieces(1)
                setSearchPiece(searchQuery)
                setReload(Date.now())
            }
        }

    }

    const duplicatePiece = async (piece) => {
        const result = await getOnePiece(user.token,piece.id);
        let copyPiece = {...result.data,
            name:result.data.name + t('common:catalogue.bloc.catalog.copy')};
        delete copyPiece.id


        console.log(copyPiece);

        await createPiece(user.token, copyPiece);

        setReload(Date.now())
    }

    const getOrder = (key, order) => {

        if (!loading) {

            setLoading(true);
            setOrderPiece({attribute: key, order: order})
            setReload(Date.now());
        }
    };

    const orderPieceTypes = (key, order) => {
        if (!loadingTypology) {
            setLoadingTypology(true);
            setOrderPieceType({attribute: key, order: order})
            setReload(new Date())
        }
    }


    const orderAgencement = (key, order) => {
        if (!loading) {
            setLoading(true);
            setOrderPieceAgencement({attribute: key, order: order})
            setReload(new Date())
        }
    }

    const orderMenuAction = (key, order) => {
        if (!loading) {
            setLoading(true);
            setOrderMenu({attribute: key, order: order})
            setReload(new Date())
        }
    }

    // *********PAGINATION**********************
    const [currentPageIngredients, setCurrentPageIngredients] = useState(1);
    const [maxPageIngredients, setMaxPageIngredients] = useState(1);

    const [currentPageTypology, setCurrentPageTypology] = useState(1);
    const [maxPageTypology, setMaxPageTypology] = useState(1);

    const [currentPagePieces, setCurrentPagePieces] = useState(1);
    const [maxPagePieces, setMaxPagePieces] = useState(1);

    const [currentPageMenu, setCurrentPageMenu] = useState(1);
    const [maxPageMenu, setMaxPageMenu] = useState(1);

    // Fonction pour gérer le changement de page
    const handlePageChange = (pageNumber) => {
        setCurrentPageIngredients(pageNumber);
    };

    useEffect(() => {
        console.log('user', user);
    }, [user]);

    const userIsLenotre = useMemo(() => {
        // We check that the username, without diacritics, contains the word "lenotre"
        return user?.username.normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('lenotre') ?? false;
    }, [user.username]);

    useEffect(async () => {

        if(!localStorage.getItem('DIRECTION_CHOSE')) {
            setChoseDirectModal(true)
        }

        if (authorization(user, 'ROLE_CATERER')) {
            dataObj.search = orderIngredient.attribute;
            dataObj.order = orderIngredient.order;

            const r = await getAllIngredients(user.token, currentPageIngredients, onlyMyIngredients, dataObj);
            setIngredients(r?.data.ingredientListSliced);
            setMaxPageIngredients(r?.data?.totalPages);
            setLoading(false);
            setLoadingTypology(false);
            setLoadingIngredient(false);
        }
        if (authorization(user, 'ROLE_STANDIST')) {
            dataObj.search = orderPieceAgencement.attribute;
            dataObj.order = orderPieceAgencement.order;

            const r = await getAllFurniture(user.token, currentPageIngredients, onlyMyIngredients, dataObj);
            setIngredients(r?.data.furnishingsListSliced);
            setMaxPageIngredients(r?.data?.totalPages);
            setLoading(false);
            setLoadingTypology(false);
            setLoadingIngredient(false);
        }

    }, [reload, currentPageIngredients])

    useEffect(async () => {
        dataObj.search = orderPieceType.attribute;
        dataObj.order = orderPieceType.order;

        getTypeList(user.token, currentPageTypology, dataObj).then((r) => {
            setallTypes(r?.data?.typeListSliced);
            setMaxPageTypology(r?.data?.totalPages)

            setLoading(false);

        })
        checkIfUserIsSub();
    }, [reload, currentPageTypology])

    useEffect(async () => {

        dataObj.search = orderMenu.attribute;
        dataObj.order = orderMenu.order;

        getMenu(user.token, dataObj, currentPageMenu).then((r) => {
            setMenuList(r?.data?.menuListSliced)
            setMaxPageMenu(r?.data?.totalPages)
        })
    }, [reload, currentPageMenu])

    useEffect(async () => {

        dataObj.search = searchPiece;
        dataObj.order = orderPiece;

        getTypeList(user.token).then((types) => {
                setTypeList(types?.data?.typeList)
            }
        );

        getPiece(user.token, dataObj, currentPagePieces).then((r) => {
                setAllPiece(r?.data.piecesList);
                setPaginationPieces(r?.data.piecesList)
                setMaxPagePieces(r?.data?.totalPages)
                setLoading(false);
            }

        );


    }, [reload, currentPagePieces])

    function getEmissionFormula(menu) {
        let sum = 0;
        return menu.co2;
    }

    function getEmissionPerParticipant(menu) {
        const sum = getEmissionFormula(menu);
        return sum / participants;
    }

    function getTermForUserRole(key) {


        if (authorization(user, 'ROLE_CATERER')) {

            if (key == 'ingredient') {
                return t('common:catalogue.bloc.title.ingredients');
            }
            if (key == 'add-ingredient') {
                return t('common:catalogue.button.my_ingredients');
            }
            if (key == 'menu') {
                return t('common:catalogue.button.menu');
            }

        }

        if (authorization(user, 'ROLE_STANDIST')) {

            if (key == 'ingredient') {
                return t('common:catalogue.bloc.title.furnishings');
            }
            if (key == 'add-ingredient') {
                return t('common:catalogue.button.my_furnishings');
            }

        }


    }

    function calculateFret(type, piece) {

        if(piece.manufacturingDistance && piece.totalArea) {
            const mappingObj = {
                "Choisir ci-dessous…": 0,
                "+/- 50km de l'event": 0.052,
                "+/- 200km de l'event": 0.052,
                "+/- 600km de l'event": 0.052,
                "+/- 1000km de l'event": 0.052,
                "+/- 3000km de l'event": 0.1458,
                "+/- 5000km de l'event": 0.2324,
                "+/- 10000km de l'event": 0.326,
            };
            const fe = mappingObj[piece.manufacturingDistance];
            const distance = fe === 0 ? 0 : parseInt(piece.manufacturingDistance.match(/\d+(\.\d+)?/)[0]);
            const surface = piece.totalArea
            //formule extraite de : https://gitlab.com/dev-product/climeet_dev/uploads/0ca150aa83b48972b854525f79b07a08/Fret_pre%CC%81visionnel.pdf
            // Exemple : Distance (3000) * FE (0,1458) * Surface (23) / 10 = 1,006.02 kgCO2e

            if(type == 'space') {
                return (distance * fe * surface / 10).toFixed(3);
            } else {
                //Message samy 18/12 : Pour le m2 tu divises par le nb de m2
                return ((distance * fe * surface / 10)/ surface).toFixed(3);
            }
        } else {
            return "X"
        }
    }
    return (<>

        {loadingIsSub && <Loading/>}

        {choseDirectModal &&
            <ChoseDirectionModal closeModal={setChoseDirectModal}></ChoseDirectionModal>
        }

        {!loadingIsSub && !loading &&

            <div
                style={{backgroundColor: customization.backgroundColor}}
                className="bg-green-light flex flex-col items-center min-h-screen px-5">
                <h2 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                    className="py-3 font-title font-bold text-5xl "><ArrowTitle/>{t('catalogue.title')}</h2>


                {isSub &&
                    <div >
                        {/*catalog*/}
                        <div className='flex flex-wrap flex-row mt-10 gap-3.5'>
                            <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                 className='flex flex-col relative blancCasse rounded mb-3 mt-1 p-8  py-4 catalog-bloc'>

                                <div className='flex justify-between'>
                                <span>
                                    <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                        className={'text-2xl'}>{user.roles.includes('ROLE_STANDIST') ? t('catalogue.bloc.title.facilities') : t('catalogue.bloc.title.pieces')}
                                        <FontAwesomeIcon data-tooltip-id="piece-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                        {!csvDownload ?
                                            <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv(user.roles.includes('ROLE_CATERER') ? 'piece' : 'space')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                            :
                                            <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                        }
                                    </h3>
                                </span>
                                    <Tooltip id="piece-tooltip"
                                             textColor={"#fdc96e"}
                                             className={"relative z-50"}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {user.roles.includes('ROLE_STANDIST') ?
                                                <>
                                                    <span>{t('catalog.standist.space.a')}</span>
                                                    <span>{t('catalog.standist.space.b')}</span>
                                                    <span>{t('catalog.standist.space.c')}</span>
                                                </>
                                                :
                                                <>
                                                    <span>{t('catalog.caterer.piece.a')}</span>
                                                    <span>{t('catalog.caterer.piece.b')}</span>
                                                    <span>{t('catalog.caterer.piece.c')}</span>
                                                </>
                                            }
                                        </div>
                                    </Tooltip>
                                    <div className={'flex'}>
                                        {
                                            user.roles.includes('ROLE_CATERER') && userIsLenotre &&
                                            <ImportPieceButton
                                                openModalEditPiece={selectorModal}
                                                setPieceAutocomplete={setPieceAutocomplete}
                                            />
                                        }
                                        <AddPieceButton
                                            openModalEditPiece={selectorModal}
                                        />
                                    </div>
                                </div>

                                {/*search*/}
                                <div className='search_catalog'>
                                    <input type='search' placeholder={t('catalogue.bloc.catalog.search')} id='input-search_catalog'
                                           className=''
                                           value={search}
                                           onChange={handleSearchPiece}
                                        // onChange={(e) => {
                                        //     const searchQuery = e.target.value;
                                        //     if (searchQuery.length >= 3 || searchQuery.length === 0) {
                                        //         handleSearchPiece(searchQuery);
                                        //     }
                                        // }}
                                    />
                                    <FontAwesomeIcon className="mr-3 blue-unlock glass" icon={faSearch}/>
                                </div>

                                <div className='mt-8'>


                                    {!loading &&
                                        <table className={'w-full'}>
                                            <thead>
                                            <tr>
                                                <th className={'px-2 text-md'}>
                                                    <div className={'flex mb-2'}>
                                                        <p>{t('eventList.table.name')}</p>
                                                        <div className={'flex flex-col px-2 text-xs'}>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('name', 'asc')
                                                                             }} icon={faChevronUp}/>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('name', 'desc')
                                                                             }} icon={faChevronDown}/>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className={'px-2 text-md'}>
                                                    <div className={'flex mb-2'}>
                                                        <p>{t('eventList.table.type')}</p>
                                                        <div className={'flex flex-col px-2 text-xs'}>
                                                            <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('type', 'asc')
                                                                             }} icon={faChevronUp}/>
                                                            <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('type', 'desc')
                                                                             }} icon={faChevronDown}/>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className={'px-2 text-md'}>
                                                    <div className={'flex justify-end mb-2'}>
                                                        <p>{!user.roles.includes('ROLE_STANDIST') ? t('common:catalogue.bloc.catalog.kgCO2PieceStandist') :t('common:catalogue.bloc.catalog.kgCO2Piece')}</p>
                                                        <div className={'flex flex-col px-2 text-xs'}>
                                                            <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('resultByPiece', 'asc')
                                                                             }} icon={faChevronUp}/>
                                                            <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                             onClick={() => {
                                                                                 getOrder('resultByPiece', 'desc')
                                                                             }} icon={faChevronDown}/>
                                                        </div>
                                                        {user.roles.includes('ROLE_STANDIST') &&
                                                            <>
                                                                <div className={'flex shrink flex-col px-2 text-xs'}
                                                                     onMouseEnter={() => {setVisibleSpace(true)}}
                                                                     onMouseLeave={() => {setVisibleSpace(false)}}
                                                                     data-tooltip-id={'fretSpace'} data-tooltip-content={t('catalogue.bloc.catalog.fretSpace')}
                                                                >
                                                                    <FontAwesomeIcon style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className="h-5 blue-unlock cursor-pointer"
                                                                                     icon={faTruckPlane}/>
                                                                </div>
                                                                <Tooltip id={'fretSpace'} multiline={true} className="tooltip max-w-xs rounded relative z-50" textColor={"#fdc96e"}/>
                                                            </>
                                                        }

                                                    </div>
                                                </th>
                                                {authorization(user, 'ROLE_CATERER') &&
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex justify-end mb-2'}>
                                                            <p>kgCO2e/kg</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     getOrder('resultByMeasurement', 'asc')
                                                                                 }} icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     getOrder('resultByMeasurement', 'desc')
                                                                                 }} icon={faChevronDown}/>
                                                            </div>
                                                        </div>
                                                    </th>}

                                                {authorization(user, 'ROLE_STANDIST') &&
                                                    <th className={'px-2 text-md'}>
                                                        <div className={'flex justify-end mb-2'}>
                                                            <p>kgCO2e/m2</p>
                                                            <div className={'flex flex-col px-2 text-xs'}>
                                                                <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     getOrder('resultByMeasurement', 'asc')
                                                                                 }} icon={faChevronUp}/>
                                                                <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                 onClick={() => {
                                                                                     getOrder('resultByMeasurement', 'desc')
                                                                                 }} icon={faChevronDown}/>
                                                            </div>
                                                            <div className={'flex shrink flex-col px-2 text-xs'}
                                                                 data-tooltip-id={'fretM2'} data-tooltip-content={t('catalogue.bloc.catalog.fretM2')}
                                                                 onMouseEnter={() => {setVisibleM2(true)}}
                                                                 onMouseLeave={() => {setVisibleM2(false)}}
                                                            >
                                                                <FontAwesomeIcon style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className="h-5 blue-unlock cursor-pointer"
                                                                                 icon={faTruckPlane}/>
                                                            </div>
                                                            <Tooltip id={'fretM2'} multiline={true} className="tooltip max-w-xs rounded relative z-50" textColor={"#fdc96e"}/>
                                                        </div>
                                                    </th>}

                                            </tr>
                                            </thead>
                                            <tbody>
                                            {paginationPieces?.length !== 0 && paginationPieces?.map((piece) => {
                                                return <tr className={'hover:opacity-40'} key={piece.id}>
                                                    <td className="px-2 text-md truncate" style={{maxWidth: '190px'}}
                                                        title={piece.name}>{piece.name}</td>
                                                    <td className={'px-2 text-md truncate'}
                                                        style={{maxWidth: '140px'}}
                                                        title={piece?.type?.name}>{piece?.type?.name}</td>
                                                    <td className={'px-5 text-md text-right eventEllipsis'}>
                                                        <span>{piece.resultByPiece ? piece.resultByPiece.toFixed(3) : "0.000"}</span>
                                                        {authorization(user, 'ROLE_STANDIST') && visibleSpace &&
                                                            <span style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className={'visibleSpace float-right pl-5'}>{calculateFret('space', piece)}</span>
                                                        }
                                                    </td>

                                                    {authorization(user, 'ROLE_CATERER') &&
                                                        <td className={'px-5 text-md text-right eventEllipsis'}>{piece.resultByMeasurement ? piece.resultByMeasurement.toFixed(3) : "0.000" }</td>
                                                    }

                                                    {authorization(user, 'ROLE_STANDIST') &&
                                                        < td
                                                            className={'px-5 text-md text-right eventEllipsis'}>
                                                            <span>{piece.resultByMeasurement ? piece.resultByMeasurement.toFixed(3) : "0.000" }</span>
                                                            {visibleM2 &&
                                                                <span style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}} className={'visibleSpace float-right pl-5'}>{calculateFret('m2', piece)}</span>
                                                            }
                                                        </td>
                                                    }

                                                    <td className={'text-md w-0.5'}>
                                                        <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faPen}
                                                                         onClick={() => {
                                                                             selectorModal(piece)
                                                                         }}/>
                                                    </td>
                                                    <td className={'text-md w-0.5'}>
                                                        <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faCopy}
                                                                         onClick={() => {
                                                                             duplicatePiece(piece)
                                                                         }}/>
                                                    </td>
                                                    <td className={'text-md w-0.5'}>
                                                        <FontAwesomeIcon className={'mt-2 cursor-pointer yellowHover'} icon={faTrash}
                                                                         onClick={() => {
                                                                             openModalDeletePiece(piece)
                                                                         }}/>
                                                    </td>
                                                </tr>
                                            })}

                                            </tbody>
                                        </table>}
                                </div>

                                {/*****************PAGINATION PIECES***********************/}

                                {paginationPieces?.length !== 0 &&
                                    <Pagination
                                        currentPage={currentPagePieces}
                                        totalPages={maxPagePieces}
                                        onPageChange={setCurrentPagePieces}
                                        withButtons={true}
                                        setCurrentPage={setCurrentPagePieces}
                                    />
                                }
                            </div>

                            {modalShow ?
                                <ConfirmModal reloadAction={setReload} inputName={'Name'} title={t('catalogue.button.typology')}
                                              confirmBtn={t('catalogue.button.add')} selectedObject={selectedObject}
                                              finishAction={saveType} closeModal={setModalShow}/> : null}


                            <div className='flex md:w-auto flex-col typologyIngredient-bloc'>
                                {/******************************************TYPOLOGY***********************************************************************/}
                                <div className={'flex flex-row gap-2'}>
                                    <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                         className='flex flex-col relative w-3/4 blancCasse rounded mb-3 mt-1 basis-2/4 mb-8 py-4 typology-bloc'>

                                        <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                        <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                            className={'text-2xl'}>{t('common:catalogue.bloc.title.typology')}
                                            <FontAwesomeIcon data-tooltip-id="type-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                            {/* {!csvDownload ?
                                                <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv('type')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                                :
                                                <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                            } */}
                                        </h3>
                                        </span>
                                            <Tooltip id="type-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {user.roles.includes('ROLE_STANDIST') ?
                                                        <>
                                                            <span>{t('catalog.standist.type.a')}</span>
                                                            <span>{t('catalog.standist.type.b')}</span>
                                                            <span>{t('catalog.standist.type.c')}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>{t('catalog.caterer.type.a')}</span>
                                                            <span>{t('catalog.caterer.type.b')}</span>
                                                            <span>{t('catalog.caterer.type.c')}</span>
                                                        </>
                                                    }
                                                </div>
                                            </Tooltip>
                                            <button onClick={() => {
                                                openModalEditType('')
                                            }}
                                                    className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create">
                                                {t('common:catalogue.button.typology')}
                                            </button>
                                        </div>

                                        {modalTypology ?
                                            <ConfirmModal reloadAction={setReload} inputName={t('catalogue.bloc.typology.name')}
                                                          title={t('catalogue.button.typology')} isCreate={isCreate}
                                                          confirmBtn={t('catalogue.button.add')}
                                                          selectedObject={selectedObject} finishAction={saveType}
                                                          closeModal={() => {
                                                              setModalTypology(false);
                                                              setReload(Date.now())
                                                          }}/> : null}

                                        <div className='mt-5 px-5'>
                                            {!loadingTypology &&
                                                <table className={'w-full'}>
                                                    <thead>
                                                    <tr>
                                                        <th className={'px-2 text-md'}>
                                                            <div className={'flex mb-2'}>
                                                                <p>{t('eventList.table.name')}</p>
                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderPieceTypes('name', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderPieceTypes('name', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>

                                                        <th className={'px-2 text-md '}>
                                                            <div className={'flex justify-end mb-2'}>

                                                                {authorization(user, 'ROLE_CATERER') &&
                                                                    <p>{t('common:catalogue.bloc.typology.kgCO2average')}</p>}
                                                                {authorization(user, 'ROLE_STANDIST') &&
                                                                    <p>{t('common:catalogue.bloc.typology.kgCO2averageByM2')}</p>}


                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderPieceTypes('average', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderPieceTypes('average', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {allTypes?.length !== 0 && allTypes?.map((type) => {

                                                        return <tr className={'hover:opacity-40'} key={type.id}>
                                                            <td className={'px-2 text-md whitespace-nowrap overflow-hidden overflow-ellipsis'}
                                                                style={{maxWidth: '140px'}} title={type.name}>{type.name}</td>

                                                            <td className={'px-5 text-md text-right w-52'}>{
                                                                type.average ? type.average.toFixed(3) : '0.000'
                                                            }</td>


                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mr-4 mt-2 cursor-pointer yellowHover'} icon={faPen}
                                                                                 onClick={() => {
                                                                                     openModalEditType(type)
                                                                                 }}/>
                                                            </td>
                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mt-2 cursor-pointer yellowHover'} icon={faTrash}
                                                                                 onClick={() => {
                                                                                     openModalDeleteType(type)
                                                                                 }}/>
                                                            </td>
                                                        </tr>
                                                    })}

                                                    </tbody>
                                                </table>}
                                        </div>
                                        {/*****************PAGINATION TYPOLOGY***********************/}

                                        {allTypes.length !== 0 &&
                                            < Pagination
                                                currentPage={currentPageTypology}
                                                totalPages={maxPageTypology}
                                                onPageChange={setCurrentPageTypology}
                                            />
                                        }

                                    </div>

                                    {/*********************************************INGREDIENTS***********************************************************/}
                                    <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                         className='flex flex-col relative w-3/4 blancCasse rounded mb-3 mt-1 basis-2/4 py-4 ingredients-bloc'>

                                        <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                            <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                                className={'text-2xl'}>{getTermForUserRole('ingredient')}
                                                <FontAwesomeIcon data-tooltip-id="ingredient-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                                {!csvDownload ?
                                                    <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv(user.roles.includes('ROLE_CATERER') ? 'ingredient' : 'item')}} className={'ml-4 cursor-pointer text-base blancCasse yellowHover pb-0.5'} icon={faFileExport} />
                                                    :
                                                    <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                                }
                                            </h3>
                                        </span>
                                            <Tooltip id="ingredient-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {user.roles.includes('ROLE_STANDIST') ?
                                                        <>
                                                            <span>{t('catalog.standist.item.a')}</span>
                                                            <span>{t('catalog.standist.item.b')}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>{t('catalog.caterer.ingredient.a')}</span>
                                                            <span>{t('catalog.caterer.ingredient.b')}</span>
                                                            <span>{t('catalog.caterer.ingredient.c')}</span>
                                                        </>
                                                    }
                                                </div>
                                            </Tooltip>
                                            <Tooltip id="extract-csv" textColor={"#fdc96e"} className="relative z-50">
                                                <div>
                                                    <span>{t('global.extractToCSV')}</span>
                                                </div>
                                            </Tooltip>
                                            <button
                                                onClick={() => {
                                                    setSelectedIngredient({})
                                                    openModalIngredientsOrFurnitures()
                                                }}
                                                className={'btn-create text-lg border border-green-500 font-bold py-2 px-8 flex justify-center'}>
                                                {getTermForUserRole('add-ingredient')}
                                            </button>
                                        </div>

                                        <div className='mt-5 px-5'>
                                            {!loadingIngredient &&
                                                <table className={'w-full'}>
                                                    <thead>
                                                    <tr>
                                                        <th className={'px-2 text-md'}>
                                                            <div className={'flex mb-2'}>
                                                                <p>{t('eventList.table.name')}</p>
                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderAgencement('name', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderAgencement('name', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className={'px-2 text-md'}>
                                                            <div className={'flex justify-end mb-2'}>
                                                                <p>FE kgCO2e/{t('ingredients.unite')}</p>
                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderAgencement('kgCO2eBykg', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderAgencement('kgCO2eBykg', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {ingredients?.length !== 0 && ingredients?.map((ingredient) => {
                                                        return <tr className={'hover:opacity-40'} key={ingredient.id}>
                                                            <td className={'px-2 text-md flex'}><span
                                                                className={'whitespace-nowrap overflow-hidden overflow-ellipsis ingredient-name mr-1'}
                                                                title={ingredient.name}>{ingredient.name}</span><span>({ingredient.unite || 0})</span>
                                                            </td>
                                                            <td className={'px-5 text-md text-right w-52'}>{ingredient?.kgCO2eBykg || parseInt(ingredient?.ef, 10).toFixed(3)}</td>
                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mr-4 mt-2 yellowHover cursor-pointer'} icon={faPen}
                                                                                 onClick={() => {
                                                                                     setSelectedIngredient(ingredient)
                                                                                     openModalIngredientsOrFurnitures()
                                                                                 }}/>
                                                            </td>
                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mt-2 yellowHover cursor-pointer'} icon={faTrash}
                                                                                 onClick={() => {
                                                                                     setSelectedIngredient(ingredient)
                                                                                     openModalDeleteIngredientOrFurniture(ingredient)
                                                                                 }}/>
                                                            </td>
                                                        </tr>
                                                    })}

                                                    </tbody>
                                                </table>}
                                        </div>

                                        {/*****************PAGINATION INGREDIENTS***********************/}

                                        {ingredients?.length !== 0 &&
                                            <Pagination
                                                currentPage={currentPageIngredients}
                                                totalPages={maxPageIngredients}
                                                onPageChange={handlePageChange}
                                            />
                                        }

                                    </div>
                                </div>

                                {/***************DEBUT MENU******************************/}
                                {user.roles.includes('ROLE_CATERER') &&
                                    <div style={{backgroundColor: getComplementaryColor('boxColor', customization.backgroundColor)}}
                                         className='flex flex-col relative w-full blancCasse rounded mb-3 h-full mt-1 basis-1/4 py-4 menu-bloc'>

                                        <div className='flex justify-between mx-2 px-5'>
                                        <span>
                                            <h3 style={{color: getComplementaryColor('titleColor', customization.backgroundColor)}}
                                                className={'text-2xl'}>{getTermForUserRole('menu')}
                                                <FontAwesomeIcon data-tooltip-id="menu-tooltip" className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                                                {!csvDownload ?
                                                    <FontAwesomeIcon data-tooltip-id="extract-csv" onClick={() => {exportDataToCsv('menu')}} className={'blancCasse yellowHover ml-4 cursor-pointer text-base pb-0.5'} icon={faFileExport} />
                                                    :
                                                    <FontAwesomeIcon className={'ml-4 cursor-pointer text-base text-[#fdc96e] pb-0.5 fa-spin'} icon={faSpinner} />
                                                }
                                                {/* <span data-tooltip-id="my-tooltip" data-tooltip-content={authorization(user, 'ROLE_CATERER') ? t("choseDirection.tooltip_1a") : t("choseDirection.tooltip_1")} > */}
                                            </h3>
                                        </span>
                                            <Tooltip id="menu-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>{t('catalog.caterer.menu.a')}</span>
                                                    <span>{t('catalog.caterer.menu.b')}</span>
                                                </div>
                                            </Tooltip>
                                            <div className={'ml-72'}>
                                                <label htmlFor="" className={'mr-5'}>{t('menu.nb_participants')}</label>
                                                <input type="text" className='w-20 text-black'
                                                       defaultValue={participants}
                                                       onChange={(e) => {
                                                           setParticipants(e.target.value)
                                                       }}
                                                />
                                            </div>
                                            <button
                                                onClick={() => {
                                                    setSelectedMenu({})
                                                    setModalMenu(true)
                                                }}
                                                className={'btn-create text-lg border border-green-500 font-bold py-2 px-8 flex justify-center'}>
                                                {getTermForUserRole('add-ingredient')}
                                            </button>
                                        </div>

                                        <div className='mt-5 px-5'>
                                            {!loadingIngredient &&
                                                <table className={'w-full'}>
                                                    <thead>
                                                    <tr>
                                                        <th className={'px-2 text-md w-80'}>
                                                            <div className={'flex mb-2'}>
                                                                <p>{t('eventList.table.formula')}</p>
                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderMenuAction('name', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderMenuAction('name', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className={'px-2 text-md'}>
                                                            <div className={'flex justify-end mb-2'}>
                                                                <p>kgCO2e/menu</p>
                                                                <div className={'flex flex-col px-2 text-xs'}>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderMenuAction('kgCO2eBykg', 'ASC')
                                                                                     }}
                                                                                     icon={faChevronUp}/>
                                                                    <FontAwesomeIcon className="blue-unlock cursor-pointer"
                                                                                     onClick={() => {
                                                                                         orderMenuAction('kgCO2eBykg', 'DESC')
                                                                                     }}
                                                                                     icon={faChevronDown}/>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className={'px-2 text-md'}>
                                                            <div className={'flex justify-end mb-2 px-2'}>
                                                                <p>kgCO2e/participant</p>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {menuList?.length !== 0 && menuList?.map((menu) => {
                                                        return <tr className={'hover:opacity-40'} key={menu.id}>
                                                            <td className={'px-2 text-md flex'}><span
                                                                className={'whitespace-nowrap overflow-hidden overflow-ellipsis ingredient-name mr-1'}
                                                                title={menu.name}>{menu.name}</span>
                                                            </td>
                                                            <td className={'px-5 text-right text-md w-52'}>{getEmissionFormula(menu).toFixed(3)}</td>
                                                            <td className={'px-5 text-right text-md w-52'}>{participants && getEmissionPerParticipant(menu) > 0 ? getEmissionPerParticipant(menu).toFixed(3) : Number(0).toFixed(3)}</td>
                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mr-4 mt-2 yellowHover cursor-pointer'} icon={faPen}
                                                                                 onClick={() => {
                                                                                     setSelectedMenu(menu)
                                                                                     setModalMenu(true)
                                                                                 }}/>
                                                            </td>
                                                            <td className={'text-md w-0.5'}>
                                                                <FontAwesomeIcon className={'mt-2 yellowHover cursor-pointer'} icon={faTrash}
                                                                                 onClick={() => {
                                                                                     setSelectedMenu(menu)
                                                                                     setModalDeleteMenu(true)
                                                                                 }}/>
                                                            </td>
                                                        </tr>
                                                    })}

                                                    </tbody>
                                                </table>}
                                        </div>
                                        <div className={'mt-5'}>
                                            {menuList?.length !== 0 &&
                                                <Pagination
                                                    currentPage={currentPageMenu}
                                                    totalPages={maxPageMenu}
                                                    onPageChange={setCurrentPageMenu}
                                                />
                                            }
                                        </div>

                                    </div>
                                }
                                {/***************FIN MENU******************************/}
                            </div>

                            {modalPieceCaterer &&
                                <ModalPieceCaterer
                                    piece={pieceSelected}
                                    autocomplete={pieceAutocomplete}
                                    reload={setReload}
                                    typeList={typeList}
                                    setModalPiece={(enable) => {
                                        if (!enable) {
                                            setPieceAutocomplete({}); // Reset autocomplete on close
                                        }
                                        setModalPieceCaterer(enable);
                                    }}/>
                            }

                            {modalPieceStandist &&
                                <ModalPieceStandist
                                    piece={pieceSelected}
                                    reload={setReload}
                                    typeList={typeList}
                                    setModalPiece={setModalPieceStandist}/>
                            }

                            {modalDeletePiece &&
                                <DeleteModalPiece
                                    piece={pieceSelected}
                                    reload={setReload}
                                    setModalDeletePiece={setModalDeletePiece}/>
                            }

                            {modalIngredient &&
                                <ModalAddIngredient
                                    setModalIngredient={setModalIngredient}
                                    setIngredients={setIngredients}
                                    selectedIngredient={selectedIngredient}
                                    setReload={setReload}
                                />
                            }

                            {modalMenu &&
                                <ModalAddMenu
                                    paginationPieces={allPiece}
                                    setModalMenu={setModalMenu}
                                    setSelectedMenu={setSelectedMenu}
                                    selectedMenu={selectedMenu}
                                    setReload={setReload}
                                />
                            }

                            {modalFurniture &&
                                <ModalAddFurniture
                                    setModalFurniture={setModalFurniture}
                                    setFurniture={setIngredients}
                                    selectedFurniture={selectedIngredient}
                                    setReload={setReload}
                                />
                            }

                            {modalDeleteMenu &&
                                <DeleteModalMenu
                                    selectedMenu={selectedMenu}
                                    setModalDelete={setModalDeleteMenu}
                                    setSelectedMenu={setSelectedMenu}
                                    reload={setReload}
                                />
                            }

                            {modalDeleteIngredientOrFurniture &&
                                <DeleteModalIngredientOrFurniture
                                    selectedIngredient={selectedIngredient}
                                    setModalDelete={setModalDeleteIngredientOrFurniture}
                                    reload={setReload}
                                />
                            }

                            {modalDeleteType &&
                                <DeleteModal closeModal={setModalDeleteType} deleteAction={deleteTypeAction}
                                             text={'Etes-vous sûr de vouloir supprimer ce type ?'}/>
                            }
                        </div>
                    </div>
                }
                { !isSub && (
                    <div className={"mt-100"}>
                        <div className={'h-screen'}>
                            <p className="blancCasse text-center mt-48">{t('global.subscribe')}</p>
                            {/* <p className={'text-center mt-4'}><a className={'cursor-pointer btn-create'}
                                                                href="/subscription">{t('topbar.subscription')}</a>
                        </p> */}
                        </div>
                    </div>
                )}
            </div>
        }
    </>)

}