import {createIngredient, updateIngredient} from "../../../utils/api/ingredients";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {ChoiceInput} from "../../shared/input/Input";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function ModalAddIngredient(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [ingredient, setIngredient] = useState({})

    const saveIngredient = async (ingredient) => {
        if (!ingredient.name) {
            toast.error(t('ingredients.create_error'))
        } else {
            //nouvel objet qui reprend l'objet ingredient et vérifie si kgCO2eBykg est défini
            const newIngredient = {
                ...ingredient,
                kgCO2eBykg: ingredient.kgCO2eBykg ? ingredient.kgCO2eBykg : 1
            };

            //UPDATE
            if (newIngredient.id) {
                await updateIngredient(user.token, newIngredient)
                props.setModalIngredient(false);
                props.setIngredients(prevState => {
                    const updatedIngredients = prevState.map(item => item.id === newIngredient.id ? newIngredient : item);
                    return updatedIngredients;
                });
                toast.success(t('ingredients.maj'))
            } else {
                //CREATE
                await createIngredient(user.token, newIngredient)
                props.setModalIngredient(false);

                toast.success(t('ingredients.create'))
            }

            props.setReload(Date.now());


        }
    }

    useEffect(() => {
        if (props.selectedIngredient) {

            if (!props.selectedIngredient.unite) {
                props.selectedIngredient.unite = 'kg';
            }

            setIngredient(props.selectedIngredient)
        }
    }, [])


    const generateLabelButtonSave = () => {

        if (ingredient.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');

        }

    };

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalIngredient(false)}>
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{t('ingredients.add')}</h1>

                        <div className="relative p-6 flex-auto justify-center flex">
                            <div>
                                <div className={'flex mb-5'}>
                                    <div className={'mr-5'}>
                                        <label className={'inline-block mr-2'}>{t('ingredients.name')}</label>
                                        <input type={'text'}
                                               className={'w-72'}
                                               defaultValue={ingredient.name}
                                               onChange={(e) => setIngredient({...ingredient, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label className={'inline-block mr-2'}>{t('ingredients.unite')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "auto"}}
                                            value={ingredient.unite}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setIngredient({...ingredient, ...{unite: e.target.value}})}

                                            className={'force-text-black'}>
                                            <option value={'kg'}>{t('ingredients.kg')}</option>
                                            <option value={'litre'}>{t('ingredients.litre')}</option>
                                            <option value={'unite'}>{t('ingredients.unite')}</option>
                                        </ChoiceInput>
                                    </div>
                                </div>

                                <div className={'mb-2 flex items-center'}>
                                    <label className={'mr-2'}>{t('ingredients.ef')}</label>
                                    <input type={'number'}
                                           className={'w-44 mr-5'}
                                           defaultValue={ingredient.kgCO2eBykg}
                                           min={0}
                                           onChange={(e) => setIngredient({...ingredient, ...{kgCO2eBykg: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {t('ingredients.' + ingredient.unite)}</label>
                                </div>

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalIngredient(false)
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveIngredient(ingredient);
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}