import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useCallback, useRef} from "react";
import {useSelector} from "react-redux";
import {lenotreAutocomplete} from "../../utils/api/lenotreAutocomplete";

export default function ImportPieceButton(props) {
    const [t] = useTranslation("common");
    const inputRef = useRef(null);
    const {user} = useSelector(state => state.security)

    const onChangeFile = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('spreadsheet', file);
        lenotreAutocomplete(user.token, formData).then(res => {
            if (res.error) {
                toast.error(t('common:catalogue.toast.import_piece_error'));
            } else {
                toast.success(t('common:catalogue.toast.import_piece_success'));
                toastSkippedIngredients(res.data.skippedIngredients);
                props.setPieceAutocomplete(res.data);
                props.openModalEditPiece(null);
            }
        });
    }, []);

    const toastSkippedIngredients = useCallback((skippedIngredients) => {
        if (skippedIngredients.length > 0) {
            const pieces = skippedIngredients.map(ingredient => `- ${ingredient}`).join('\n');
            toast.info(`${t('common:catalogue.toast.ingredients_skipped')}\n${pieces}`, {
                autoClose: false,
            });
        }
    }, []);

    return (<>
        <button
            onClick={() => {
                inputRef.current?.click();
            }}
            className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create">
            {t('common:catalogue.button.import_piece')}
        </button>
        <input type={'file'} ref={inputRef} className={'hidden'} onChange={onChangeFile}/>

    </>);
}