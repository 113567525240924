import {deleteMenuApi} from "../../../utils/api/menu";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function DeleteModalMenu(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')

    useEffect(() => {

    }, [])

    const removeMenu = async () => {
        try {
            await deleteMenuApi(user.token, props.selectedMenu);
            props.reload(Date.now());
            toast.success(t("menu.delete"));
            props.setSelectedMenu({});
        } catch (error) {
            if (error.response) {
                toast.error(t('menu.menuInEvent'));
            } else {
                // Gérer les erreurs de réseau ou autres erreurs non liées à la réponse HTTP
                toast.error("Network Error");
            }
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}
                        <div className="relative p-6 flex-auto blancCasse font-bold">
                            {(t('menu.delete_title') + " ?")}
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDelete(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    removeMenu();
                                    props.setModalDelete(false);
                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}