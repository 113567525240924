import {createPiece, getOnePiece, updatePiece} from "../../../utils/api/catalogue";
import {TOAST_CONFIG} from "../../../constants/toast";
import {authorization} from "../../../actions/securityAction";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {ChoiceInput} from "../../shared/input/Input";
import {GenerateOptions} from "../../../hook/translation";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faXmark,
    faCircleInfo,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from 'react-tooltip'
import AddRecipeStandist from "./addRecipeStandist";

const emptyObjectPieceForStandists = {
    name: '',
    type: null,
    productBio: null,
    productLocal: null,
    numberOfPieceByRecipe: 1,
    pieceGrammes: null,
    knownCo2ByPiece: null,
    knownCo2ByKg: null,
    metreCarre: null,
    metreCube: null,
    distance: null,
    total_area: null,
    localManufacturing: null,
    manufacturingDistance: null,
    recycled: null,
    recipes: [],
    result: [],
    recipeFurnishings: [],
};

export default function ModalPieceStandist(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [isLoading,setIsLoading] = useState(false);
    const [piece, setPiece] = useState(emptyObjectPieceForStandists)

    useEffect(() => {
        initPiece(props.piece);
    }, [props.piece])

    async function initPiece(inputPiece){

        if (inputPiece) {
            if(isLoading) return;

            setIsLoading(true);
            try {
                const result = await getOnePiece(user.token, inputPiece.id);
                setPiece(result.data);
            } catch(e) {
                console.error(e);
                toast.error("Cannot load piece");
                props.reload(Date.now());
                props.setModalPiece(false)
            } finally {
                setIsLoading(false);
            }
        } else {
            piece.recipeFurnishings = [] //avoid recipes datas persist when create
            piece.recipes = []; // same
        }
    }


    const savePiece = async () => {

        piece.mode = 'standist';
        if (!piece.name) {
            toast.error(t('facility.error'))
        } else {
            if (piece.id) {
                await updatePiece(user.token, piece);
                toast.success(t('facility.update'), TOAST_CONFIG);
            } else {
                await createPiece(user.token, piece);
                toast.success(t('facility.create'), TOAST_CONFIG);
            }

            props.reload(Date.now());
        }
    }


    const changeLabelButton = () => {


        return !piece.id ? t('piece.add') : t('piece.update');

    }

    const changeLabelTitleModal = () => {

        if(authorization(user, 'ROLE_STANDIST')){
            return !piece.id ? t('piece.add_modal_title_standist') : t('piece.update_modal_title_standist');
        }
        if(authorization(user, 'ROLE_CATERER')){
            return !piece.id ? t('piece.add_modal_title') : t('piece.update_modal_title');
        }
    }

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black" onClick={() => props.setModalPiece(false)}></div>
            <div
                className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalPiece(false)}
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => props.setModalPiece(false)}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{changeLabelTitleModal()}</h1>
                        {/*body*/}
                        {isLoading && <FontAwesomeIcon className={'mx-auto text-base text-[#fdc96e] mb-6 fa-spin'} icon={faSpinner} />}
                        {!isLoading && <>
                            <p className={'text-black font-semibold  text-center mb-6'}>{t('piece.parameters')}</p>

                            <div className=" p-6 flex-auto">


                                <div id='form-piece'>
                                    <div className={'mb-2'}>
                                        <label className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.name')}</label>
                                        <input type={'text'} value={piece.name} style={{width: "200px"}}
                                               onChange={(e) => setPiece({...piece, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.piece_type')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "200px"}}
                                            value={piece.type?.id}
                                            onChange={(e) => setPiece({...piece, ...{type: {id: e.target.value}}})}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                        >
                                            <option value="null">Aucun</option>
                                            {props.typeList.map(option => (
                                                <option key={option.name} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </ChoiceInput>
                                    </div>

                                    {/*//fabrication locale*/}
                                    <div className={'mb-2'}>
                                        <label className={'inline-block w-1/2 mr-2 text-right'}>
                                            {t('piece.local_manufacturing')}
                                        </label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "200px"}}
                                            value={piece.localManufacturing || t('piece.list.local_manufacturing.1')}
                                            onChange={(e) => {
                                                setPiece({...piece, ...{localManufacturing: e.target.value}})
                                            }
                                            }
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                        >
                                            {
                                                GenerateOptions(
                                                    t('piece.list.local_manufacturing', {returnObjects: true}),
                                                    t('piece.list.local_manufacturing', {returnObjects: true, lng: 'fr'}),
                                                    props.premium,
                                                    true
                                                )
                                            }

                                        </ChoiceInput>
                                    </div>

                                    {/*Distance de l'évent*/}
                                    <div className={'mb-2'}>
                                        <label className={'inline-block w-1/2 mr-2 text-right'}>
                                            {t('piece.distance')}
                                        </label>
                                        <ChoiceInput
                                            name="manufacturing_distance"
                                            style={{width: "200px"}}
                                            value={piece.manufacturingDistance}
                                            onChange={(e) => setPiece({...piece, ...{manufacturingDistance: e.target.value}})}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                        >
                                            {/*Génère les options en fonction du choix pour fabrication locale*/}
                                            {piece.localManufacturing === 'Yes' || piece.localManufacturing === 'Oui' ? (
                                                GenerateOptions(
                                                    t('piece.list.manufacturing_distance_yes', {returnObjects: true}),
                                                    t('piece.list.manufacturing_distance_yes', {
                                                        returnObjects: true,
                                                        lng: 'fr'
                                                    }),
                                                    props.premium,
                                                    true
                                                )
                                            ) : (
                                                GenerateOptions(
                                                    t('piece.list.manufacturing_distance_no', {returnObjects: true}),
                                                    t('piece.list.manufacturing_distance_no', {
                                                        returnObjects: true,
                                                        lng: 'fr'
                                                    }),
                                                    props.premium,
                                                    true
                                                )
                                            )
                                            }
                                        </ChoiceInput>
                                    </div>

                                    {/*recycled*/}
                                    <div className={'mb-2'}>
                                    <span data-tooltip-id="percentRecycled-tooltip" className={'inline-block w-1/2 mr-2 text-right'}>
                                        <label>{t('piece.recycled')}</label>
                                        {user.roles.includes('ROLE_STANDIST') &&
                                            <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e]'} icon={faCircleInfo} />
                                        }
                                    </span>
                                        {user.roles.includes('ROLE_STANDIST') &&
                                            <Tooltip id="percentRecycled-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span>{t('catalog.standist.space.percentRecycledSourceA')}</span>
                                                    <span>{t('catalog.standist.space.percentRecycledSourceB')}</span>
                                                    <span>{t('catalog.standist.space.percentRecycledSourceC')}</span>
                                                </div>
                                            </Tooltip>
                                        }
                                        <ChoiceInput
                                            name="recycled"
                                            style={{width: "200px"}}
                                            value={piece.recycled}
                                            onChange={(e) => setPiece({...piece, ...{recycled: e.target.value}})}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                        >
                                            {
                                                GenerateOptions(
                                                    t('piece.list.recycled', {returnObjects: true}),
                                                    t('piece.list.recycled', {returnObjects: true, lng: 'fr'}),
                                                    props.premium,
                                                    true
                                                )
                                            }
                                        </ChoiceInput>
                                    </div>

                                    {/*nb element considéré*/}
                                    {/*<div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.elements_considered')}</label>
                                        <input type={'number'} min={0} value={piece.numberOfPieceByRecipe}
                                               style={{width: "200px"}}
                                               onChange={(e) => setPiece({...piece, ...{numberOfPieceByRecipe: e.target.value}})}
                                        />
                                    </div>*/}

                                    {/*surface total*/}
                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.total_area')}</label>
                                        <input type={'number'} min={0} value={piece.totalArea} style={{width: "200px"}}
                                               onChange={(e) => setPiece({...piece, ...{totalArea: e.target.value}})}
                                        />
                                    </div>


                                </div>


                            </div>


                            <div>
                                <AddRecipeStandist
                                    piece={piece}
                                    setPiece={setPiece}
                                />
                            </div>


                            {/*footer*/}
                            <div
                                className="flex items-center mt-10 justify-center p-6 border-t border-solid border-slate-200 rounded-b">


                                <button
                                    className="text-red-500 font-bold  px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => props.setModalPiece(false)}
                                >
                                    {t('eventList.modal.cancel')}
                                </button>

                                <button
                                    className=" btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                        savePiece()
                                        setPiece(emptyObjectPieceForStandists)
                                        props.setModalPiece(false)
                                    }}
                                >
                                    {changeLabelButton()}
                                </button>


                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )

}