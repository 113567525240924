import Disclosure from "./index";
import {ChoiceInput} from "../shared/input/Input";
import {GenerateOptions} from "../../hook/translation";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from 'react-tooltip'

export default function DisclosureCategoryFurnishings(props) {
    const [t] = useTranslation('common')
    const hiddenFurnishing = [
        "Béton (kg)", "Chaise en acier (nb)","Chaise en plastique (nb)","Table en acier (nb)","Table en plastique (nb)",
        "Coffret électrique gros (nb)", "Coffret électrique petit (nb)", "Groupe électrogène (puissance - kW)"
    ];

    const getTraduction = (category, ingredientName) => {


        if (category === 'custom') {
            return ingredientName;
        } else {

            return t('piece.furnishings.product.' + ingredientName);
        }


    }

    const displayIngredientName = (category, ingredientName) => {

        let termToDisplay = getTraduction(category, ingredientName);

        if (termToDisplay.length > 50) {

            return termToDisplay.slice(0, 50) + ('...');
        } else {
            return termToDisplay;

        }

    }

    return <>


        <Disclosure
            size="8xl"
            label={{
                text: t('piece.furnishings.category.' + props.categoryName.trim()),
                color: "white",
            }}
            MoreclassName={"flex"}
        >

            {props?.ingredientList?.length !== 0 &&
                <div className={'flex'}>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '}
                       style={{width: "200px", marginRight: '5px'}}></p>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center'}
                       style={{width: "200px", marginRight: '5px'}}>
                            <span data-tooltip-id="quantity-tooltip">
                            {t('piece.furnishings.quantity')}
                                <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e]'} icon={faCircleInfo} />
                            </span>
                    </p>
                    <Tooltip id="quantity-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                        <span>{t('catalog.standist.space.quantity')}</span>
                    </Tooltip>
                    <p className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '}
                       style={{width: "200px", marginRight: '5px'}}>{t('piece.furnishings.percentage_reused')}</p>
                    <p data-tooltip-id="percentRecycledPerCategory-tooltip" className={'blancCasse inline-block font-semibold  mb-4 flex items-end justify-center '} style={{
                        width: "200px",
                        marginRight: '5px',
                        marginLeft: '15px'
                    }}>
                        {t('piece.furnishings.nbr_reused')}
                        <FontAwesomeIcon className={'ml-2 text-base text-[#fdc96e] pb-0.5'} icon={faCircleInfo} />
                    </p>
                    <Tooltip id="percentRecycledPerCategory-tooltip" textColor={"#fdc96e"} className={"relative z-50"}>
                        <div className="flex flex-col">
                            <span>{t('catalog.standist.space.percentRecycledPerCategoryA')}</span>
                            <span>{t('catalog.standist.space.percentRecycledPerCategoryB')}</span>
                        </div>
                    </Tooltip>


                </div>
            }
            {props?.ingredientList?.length !== 0 && props.ingredientList?.filter(ingredient => ingredient.category.trim() === props.categoryName).map((ingredient) => {
                if(!hiddenFurnishing.includes(ingredient.name)) {
                    return <div className={'flex mb-2'}>
                        <label
                            title={getTraduction(props.categoryName, ingredient.name)}
                            className={'inline-block w-1/2 mr-4 mt-1  text-right'}>{displayIngredientName(props.categoryName, ingredient.name)}
                        </label>

                        <div className={'mb-2'}>
                            <input type={'number'} min='0' style={{width: "200px", height: '40px', marginRight: '5px'}}
                                   onChange={(e) => props.setDataInRecipe(ingredient, 'qty', e.target.value)}
                                   value={props.getValue(ingredient, 'qty')}
                            />
                        </div>

                        <div className={'mb-2'}>
                            <ChoiceInput
                                name="reused"
                                style={{width: "200px", height: '40px', marginRight: '5px'}}
                                value={props.getValue(ingredient, 'reused')}
                                textColorClass={"select-text-color-black"}
                                onChange={(e) => props.setDataInRecipe(ingredient, 'reused', e.target.value)}
                                className={'force-text-black'}
                            >

                                {GenerateOptions(
                                    t('furnishings.recycled', {returnObjects: true}),
                                    t('furnishings.recycled', {returnObjects: true, lng: 'fr'}),
                                    props.premium,
                                    true
                                )}

                            </ChoiceInput>
                        </div>

                        <div className={'mb-2'}>
                            <input type={'number'} min='0' name={'reusedNumber'}
                                   style={{width: "200px", height: '40px', marginRight: '5px'}}
                                   onChange={(e) => {
                                       const value = e.target.value;
                                       props.setDataInRecipe(ingredient, 'reusedNumber', value === '' ? null : value);
                                   }}
                                   value={props.getValue(ingredient, 'reusedNumber')}
                            />
                        </div>

                    </div>;
                }
            })}
        </Disclosure>


    </>
}