import {saveMenuApi, updateMenuApi} from "../../../utils/api/menu";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {SearchableInput} from "../../shared/input/Input";
import {get_class_color} from "../../../hook/get_class_color";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {getSimplePieces} from "../../../utils/api/catalogue";
import Loading from "../../loading/Loading";

export default function ModalAddMenu(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [isLoading,setIsLoading] = useState(false);
    const [t] = useTranslation('common')
    const [pieces, setPieces] = useState([])
    const [piecesInSelect, setPiecesInSelect] = useState([])
    const [piecesToDisplay, setPiecesToDisplay] = useState([])
    const [menu, setMenu] = useState({})


    const saveMenu = async () => {
        let tempMenu = {...menu}
        if(props.selectedMenu.name) {
            const update = await updateMenuApi(user.token, tempMenu);
        } else {
            const save = await saveMenuApi(user.token, tempMenu);
        }
        toast.success(t('menu.maj'));
        props.setModalMenu(false);
        props.setReload(Date.now());
        props.setSelectedMenu({})
    }

    useEffect(() => {
        setMenu(props.selectedMenu)
        getPieces();
    },[])

    // this is made to homogenize data format, to display & to select
    useEffect(() => {
        updateModale();
    },[menu, pieces])

    async function getPieces(){
        // Avoid double call
        if(isLoading) return;

        setIsLoading(true);

        try{
            const result = await getSimplePieces(user.token);
            setPieces(result.data.piecesList);
        } catch(e){
            console.error(e);
            toast.error('Cannot load menu modal');
            props.setModalMenu(false);
            props.setReload(Date.now());
            props.setSelectedMenu({})
        } finally {
            setIsLoading(false);
        }
    }

    function updateModale() {

        const transformedPiecesInSelect = pieces.map((piece) => ({id: piece?.id, name: piece?.name}))
        let filteredPiecesInSelect = transformedPiecesInSelect;

        if (menu.pieces) {

            // ugly patch, when you delete a piece that is in a menu, it stays in the menu and crash
            // this avoids reading pieces that are partially deleted
            const deepMenuPiecesCopy = structuredClone(menu.pieces)
            const filteredDeepMenuPiecesCopy = deepMenuPiecesCopy.filter(item => item.pieces.length > 0)

            filteredPiecesInSelect = transformedPiecesInSelect.filter((transformedPiece) =>
                !filteredDeepMenuPiecesCopy.some((selectedPiece) =>
                    selectedPiece.pieces[0].id === transformedPiece.id
                )
            );

            let tempPiecesToDisplay = [...piecesToDisplay]
            tempPiecesToDisplay = filteredDeepMenuPiecesCopy.map(piece => {
                return {number:piece.number, name:piece.pieces[0].name, id:piece.pieces[0].id}
            })
            setPiecesToDisplay(tempPiecesToDisplay)
        }

        setPiecesInSelect(filteredPiecesInSelect)
    }

    // Delete a piece from menu
    function removePiece(pieceId) {
        let tempMenu = {...menu};
        tempMenu.pieces = tempMenu.pieces.filter(piece =>
            !piece.pieces.some(subPiece => subPiece.id === pieceId)
        );
        setMenu(tempMenu);
    }

    // Change number of pieces in menu
    function handleChangeMenu(value, pieceId) {
        let tempMenu = { ...menu };

        tempMenu.pieces = tempMenu.pieces.map(piece => {
            const containsTargetId = piece.pieces.some(subPiece => subPiece.id === pieceId);

            if (containsTargetId) {
                return { ...piece, number: Number(value) };
            }

            return piece;
        });

        setMenu(tempMenu);
    }

    // Add a piece in menu
    function addPiece(selectedId) {
        let tempMenu = { ...menu };
        const selectedPiece = piecesInSelect.find(piece => piece.id === selectedId);

        const newPiece = {
            number: 0,
            pieces: [selectedPiece]
        };
        // if there's already pieces in menu.pieces

        if (Array.isArray(tempMenu.pieces)) {
            tempMenu.pieces.push(newPiece);
        } else {
            tempMenu.pieces = [newPiece];
        }

        setMenu(tempMenu);
    }


    const generateLabelButtonSave = () => {
        if (menu.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');
        }
    };

    return (
        <>

            <div className="fixed inset-0 opacity-25 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalMenu(false)}>
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-[85vh] 2xl:min-h-[60vh] 2xl:max-h-[85vh] 2xl:h-fit bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-4 2xl:my-6 text-2xl px-6'}>{t('menu.add')}</h1>

                        {/*content*/}
                        <div className="relative px-6 flex-auto justify-center overflow-y-auto flex">
                            <div className={'w-full'}>
                                <div className={'flex my-2 2xl:my-4'}>
                                    <div className={'flex-row items-center justify-center w-full'}>
                                        <label className={'inline-block mr-4'}>{t("menu.name")}</label>
                                        <input type={'text'}
                                               className={'w-4/6 h-3/4 placeholder-gray-500'}
                                               defaultValue={menu.name || ''}
                                               onChange={(e) => setMenu({...menu, ...{name: e.target.value}})}
                                               placeholder={""}
                                        />
                                    </div>
                                </div>
                                <div className={"flex flex-row text-black"}>
                                    <p className={'blancCasse inline-block mr-4'}>{t("menu.add_piece")}</p>
                                    {isLoading && <FontAwesomeIcon className={'mx-auto text-base text-[#fdc96e] fa-spin'} icon={faSpinner} />}
                                    {!isLoading && <>
                                        <div >
                                            <SearchableInput
                                                style={{padding: "0.25rem 0.5rem"}}
                                                styleClass={"menuSearchInput"}
                                                bg_sup={get_class_color("travel-blue", props.premium)}
                                                options={piecesInSelect}
                                                onSelectChange={(selectedOptionId) => {
                                                    addPiece(selectedOptionId);
                                                }}
                                            />
                                        </div>
                                    </>}
                                </div>
                                <div className={"py-2 2xl:py-4"}>
                                    {piecesToDisplay.length > 0 &&
                                        <table>
                                            <tr className={'blancCasse'}>
                                                <th></th>
                                                <th>Nb {t('menu.pieces')}</th>
                                            </tr>

                                            {piecesToDisplay.map((piece, k) => {
                                                return (
                                                    <tr key={k}>
                                                        <td className={'w-56 truncate blancCasse'}>{piece.name}</td>
                                                        <td>
                                                            <input className={'w-24 mx-4'}  type="number"
                                                                   value = {piece.number}
                                                                   onChange={(e) => handleChangeMenu(e.target.value, piece.id)}
                                                                   min="0"
                                                            />
                                                        </td>
                                                        <td>
                                                            <FontAwesomeIcon className="mr-3 blue-unlock cursor-pointer blancCasse yellowHover"
                                                                             onClick={() => {
                                                                                 removePiece(piece.id);
                                                                             }} icon={faTrash}/>
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    }
                                </div>

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center border-t border-solid border-slate-200 justify-center px-6 py-4 2xl:py-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalMenu(false)
                                    // setDontUpdateNow(false);
                                    props.setSelectedMenu({})
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveMenu();
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}