import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function AddPieceButton(props) {
    const [t] = useTranslation("common");


    return (<>
        <button
            onClick={() => {
                props.openModalEditPiece(null)
            }}
            className="text-lg bg-blue-600 ml-5 border border-blue-500 font-bold  px-8 flex justify-center btn-create">
            {t('common:catalogue.button.piece')}
        </button>

    </>);
}