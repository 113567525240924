import {getAllFurniture} from "../../../utils/api/catalogue";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DisclosureCategoryFurnishings from "../../disclosure/disclosureCategoryFurnishings";
import {toast} from "react-toastify";

export default function AddRecipeStandist(props) {

    const {user} = useSelector(state => state.security)
    const [furnishingsList, setFurnishingsList] = useState([]);
    const [t] = useTranslation('common')
    useEffect(async () => {

        const r = await getAllFurniture(user.token);
        setFurnishingsList(r?.data?.furnishingsList);

    }, [])

    const catAvoidDefaultValue = ["Menuiserie/Structure","Tapisserie","Signalétique"];

    const getValue = (ingredient, key) => {

        let i = props?.piece?.recipeFurnishings?.filter(element => element.furnishing[0]?.id === ingredient.id);

        if(i && i[0]){
            return i[0][key];
        } else if(key === "reused"){
            if(!catAvoidDefaultValue.includes(ingredient.category)) {
                return t("furnishings.recycled.7", {lng: 'fr'});
            }
        }

        return null;


        //return i[0] ? i[0][key] : null;
    }

    const setDataInRecipe = (ingredient, key, value) => {

        const ing = props?.piece?.recipeFurnishings?.filter(element => element.furnishing[0]?.id === ingredient.id);

        if (ing && ing.length !== 0) {

            ing[0][key] = value

        } else {

            props.piece.recipeFurnishings.push({[key]: value, furnishing: [ingredient]});
        }

        props.setPiece({...props.piece});
    }


    const categoryIngredient = [
        'Menuiserie/Structure',
        'Tapisserie',
        'Mobilier',
        'Electricité',
        'Décoration florale',
        'Signalétique',
        'Vidéo/Son',
        'Matériel traiteur',
        //'Machines', -- hidden
        'Sécurité',
        'custom'

    ];



    return (
        <>
            <p className={'text-black font-semibold text-center my-6'}>{t('piece.furnishings.title')}</p>

            {categoryIngredient.map((cat) => {
                return <DisclosureCategoryFurnishings
                    categoryName={cat}
                    ingredientList={furnishingsList}
                    setDataInRecipe={setDataInRecipe}
                    getValue={getValue}

                />

            })}


        </>
    )

}