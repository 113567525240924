import {deletePiece} from "../../../utils/api/catalogue";
import {TOAST_CONFIG} from "../../../constants/toast";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function DeleteModalPiece(props) {

    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piece, setPiece] = useState([])

    useEffect(() => {

        setPiece(props.piece);
    }, [])


    const removePiece = async () => {
        try {
            await deletePiece(user.token, piece);
            toast.success("Supprimé", TOAST_CONFIG)
            props.reload(Date.now());
        } catch (error) {
            console.error("Error encountered:", error)
            if (error.status === 409) {
                toast.error(t('eventList.delete_piece.toasts.piece_used_in_event'), TOAST_CONFIG);
            } else {
                toast.error(error.message || t('eventList.delete_piece.toasts.random_error'), TOAST_CONFIG);
            }
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <div className="relative p-6 flex-auto blancCasse font-bold">
                            {t("piece.delete",{value:props.piece.name})}
                        </div>


                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">
                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => props.setModalDeletePiece(false)}
                            >
                                {t('eventList.modal.cancel')}
                            </button>
                            <button
                                className="btn-create"
                                type="button"
                                onClick={(e) => {
                                    removePiece();
                                    props.setModalDeletePiece(false);

                                }}
                            >
                                {t('eventList.modal.confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}