import Disclosure from "./index";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function DisclosureCategoryIngredient(props) {
    const [t] = useTranslation('common')


    const getTraduction = (category, ingredientName) => {


        if (category === 'custom') {
            return ingredientName;
        } else {

            return t('piece.ingredient.product.' + ingredientName);
        }


    }

    const displayIngredientName = (category, ingredientName) => {

        let termToDisplay = getTraduction(category, ingredientName);

        if (termToDisplay.length > 50) {

            return termToDisplay.slice(0, 50) + ('...');
        } else {
            return termToDisplay;

        }

    }
    return <>


        <Disclosure
            size="8xl"
            label={{
                text: t('piece.ingredient.category.' + props.categoryName.trim()),
                color: "white",
            }}
            MoreclassName={"flex"}
            tooltip={props.tooltip}
        >
            {/* Afficher ici un message quand aucun ingrédient n'est présent (pareil pour standists & items) */}
            {props?.ingredientList?.length !== 0 && props.ingredientList?.filter(ingredient => ingredient.category.trim() === props.categoryName).map((ingredient, i) => {
                return (
                    <div>
                        {i === 0 && ingredient.category === 'Boissons' && ingredient.category !== 'custom' &&
                            <div className={'flex justify-end'}>
                                <p className={'blancCasse inline-block font-semibold  mr-48 mb-4  text-right'}>{t('piece.ingredient.liters')}</p>
                            </div>
                        }
                        {i === 0 && ingredient.category !== 'Boissons' && ingredient.category !== 'custom' &&
                            <div className={'flex justify-end'}>
                                <p className={'blancCasse inline-block font-semibold  mr-48 mb-4  text-right'}>{t('piece.ingredient.quantity')}</p>
                            </div>
                        }
                        <div className={'flex mb-2'}>
                            <label
                                title={getTraduction(props.categoryName, ingredient.name)}
                                className={'inline-block w-1/2 mr-4 mt-1  text-right'}>{displayIngredientName(props.categoryName, ingredient.name)}</label>
                            <div className={'mb-2'}>
                                <input type={'number'} min='0' style={{width: "230px", height: '30px'}}
                                       onChange={(e) => props.setQtyInRecipe(ingredient, e.target.value)}
                                       value={props.getValue(ingredient)}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
        </Disclosure>


    </>
}