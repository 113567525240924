import {getAllIngredients} from "../../../utils/api/catalogue";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DisclosureCategoryIngredient from "../../disclosure/disclosureCategoryIngredient";
import {toast} from "react-toastify";

export default function AddRecipeCaterer(props) {

    const {user} = useSelector(state => state.security)
    const [ingredientList, setIngredientList] = useState([]);
    const [t] = useTranslation('common')
    const [onlyMyIngredients, setOnlyMyIngredients] = useState(true);

    useEffect(async () => {

        const r = await getAllIngredients(user.token);
        setIngredientList(r?.data?.ingredientList);

    }, [])




    const getValue = (ingredient) => {

        let i = props?.piece?.recipes?.filter(element => element.ingredient[0]?.id === ingredient.id);

        return i[0] ? i[0].qty : null;
    }

    const setQtyInRecipe = (ingredient, qty) => {


        const ing = props?.piece?.recipes?.filter(element => element.ingredient[0]?.id === ingredient.id);

        if (ing && ing.length !== 0) {

            ing[0].qty = qty

        } else {

            props.piece.recipes.push({qty: parseInt(qty), ingredient: [ingredient]});
        }

        props.setPiece({...props.piece});

    }


    const categoryIngredient = [
        {name:"Epicerie salée"},
        {name:"Fruits/Légumes/Légumineuses",tooltip:t('piece.tooltip.vegetables_and_fruits')},
        {name:"Boulangerie/Pâtisserie"},
        {name:"BOF/Produits laitiers/Matières grasses"},
        {name:"Produits de la Mer"},
        {name:"Viandes/Charcuterie"},
        {name:"Boissons"},
        {name:"Epicerie sucrée"},
        {name:"Alternatives végétales"},
        {name:"custom"}
    ];

    const hiddenIngredients = [];


    return (
        <>
            <p className={'text-black font-semibold text-center my-6'}>{t('piece.ingredient.title')}</p>

            {categoryIngredient.map((cat) => {
                if(!hiddenIngredients.includes(cat)) {
                    return <DisclosureCategoryIngredient
                        categoryName={cat.name}
                        tooltip={cat.tooltip}
                        ingredientList={ingredientList}
                        setQtyInRecipe={setQtyInRecipe}
                        getValue={getValue}

                    />
                }
            })}


        </>
    )

}