import {createPiece, getAllIngredients, getOnePiece, updatePiece} from "../../../utils/api/catalogue";
import {TOAST_CONFIG} from "../../../constants/toast";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {ChoiceInput} from "../../shared/input/Input";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faXmark,
    faCircleInfo,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import AddRecipeCaterer from "./addRecipeCaterer";
import {Tooltip} from 'react-tooltip'

const emptyObjectPiece = {
    name: '',
    type: null,
    productBio: null,
    productLocal: null,
    numberOfPieceByRecipe: null,
    pieceGrammes: null,
    knownCo2ByPiece: null,
    knownCo2ByKg: null,
    metreCarre: null,
    metreCube: null,
    distance: null,
    total_area: null,
    localManufacturing: null,
    manufacturingDistance: null,
    recycled: null,
    recipes: [],
    result: [],
    recipeFurnishings: [],
};

export default function ModalPieceCaterer(props) {


    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [piece, setPiece] = useState(emptyObjectPiece)
    const [displayKnowImpact, setDisplayKnowImpact] = useState(false)
    const [isLoading,setIsLoading] = useState(false);
    const optionList = [t('piece.optionList.one'), t('piece.optionList.two'), t('piece.optionList.three'), t('piece.optionList.four')];

    useEffect(() => {
        initPiece(props.piece, props.autocomplete);

    }, [props.piece, props.autocomplete])

    const getRecipesFromAutocompletion = useCallback(async (autocomplete) => {
        const r = await getAllIngredients(user.token);
        const ingredientList = r?.data?.ingredientList;

        return Object.entries(autocomplete.values).map(([ingredientId, qty]) => {
            return {
                qty: qty,
                ingredient: [ingredientList.find(ingredient => ingredient.id === parseInt(ingredientId))]
            }
        });

    }, []);

    async function initPiece(piece, autocomplete = null){

        if (autocomplete && Object.keys(autocomplete).length > 0) {
            setPiece({ ...emptyObjectPiece, pieceGrammes: autocomplete.weightG, recipes: await getRecipesFromAutocompletion(autocomplete) });
        } else if (piece) {

            if(isLoading) return;

            setIsLoading(true);
            try {
                const result = await getOnePiece(user.token, piece.id);
                // Crée une copie de l'objet pièce
                let newPiece = {...result.data};

                // If knownCo2ByPiece est défini, supprime knownCo2ByKg
                if (newPiece.knownCo2ByPiece && newPiece.knownCo2ByPiece !== 0 && newPiece.knownCo2ByPiece !== "") {
                    newPiece.knownCo2ByKg = null;
                }

                // If knownCo2ByKg est défini, supprime knownCo2ByPiece
                if (newPiece.knownCo2ByKg && newPiece.knownCo2ByKg !== 0 && newPiece.knownCo2ByKg !== "") {
                    newPiece.knownCo2ByPiece = null;
                }


                setPiece(newPiece);

                if (newPiece.knownCo2ByPiece || newPiece.knownCo2ByKg) {
                    setDisplayKnowImpact(true);
                }
            } catch(e) {
                console.error(e);
                toast.error("Cannot load piece");
                props.reload(Date.now());
                props.setModalPiece(false)
            } finally {
                setIsLoading(false);
            }
        } else {
            setPiece({ ...emptyObjectPiece, recipes: [] }); //avoid recipes datas persist when create
        }
    }



    const savePiece = async () => {

        const newPiece = { ...piece, mode: 'caterer' };


        if (!newPiece.name) {
            toast.error(t('piece.create_error'));
        } else {
            // Si décoché, force les valeurs à 0
            if (!displayKnowImpact) {
                newPiece.knownCo2ByKg = 0;
                newPiece.knownCo2ByPiece = 0;
            } else {
                // If knownCo2ByPiece est défini, supprime knownCo2ByKg
                if (newPiece.knownCo2ByPiece && newPiece.knownCo2ByPiece !== 0 && newPiece.knownCo2ByPiece !== "") {
                    newPiece.knownCo2ByPiece = Number(newPiece.knownCo2ByPiece)
                    newPiece.knownCo2ByKg = null;
                }

                // If knownCo2ByKg est défini, supprime knownCo2ByPiece
                if (newPiece.knownCo2ByKg && newPiece.knownCo2ByKg !== 0 && newPiece.knownCo2ByKg !== "") {
                    newPiece.knownCo2ByKg = Number(newPiece.knownCo2ByKg)
                    newPiece.knownCo2ByPiece = null;
                }
            }

            if (newPiece.id) {
                await updatePiece(user.token, newPiece);
                toast.success("Pièce mise à jour", TOAST_CONFIG);
            } else {
                await createPiece(user.token, newPiece);
                toast.success("Pièce créée", TOAST_CONFIG);
            }

            props.reload(Date.now());

        }

    };


    const changeLabelButton = () => {
        return !piece.id ? t('piece.add') : t('piece.update');

    }

    const changeLabelTitleModal = () => {
        return !piece.id ? t('piece.add_modal_title') : t('piece.update_modal_title');
    }


    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black" onClick={() => props.setModalPiece(false)}></div>
            <div
                className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-red"
                onClick={() => props.setModalPiece(false)}
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="z-51 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
                        onClick={(e) => {e.stopPropagation()}}>
                        <div className="flex justify-end pt-2 pl-2 pr-2 pb-0">
                            <span className="font-bold blancCasse cursor-pointer yellowHover mr-4 mt-2" onClick={() => props.setModalPiece(false)}><FontAwesomeIcon icon={faXmark} /></span>
                        </div>
                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{changeLabelTitleModal()}</h1>
                        {/*body*/}
                        {isLoading && <FontAwesomeIcon className={'mx-auto text-base text-[#fdc96e] mb-6 fa-spin'} icon={faSpinner} />}
                        {!isLoading && <>
                            <p className={'text-black font-semibold  text-center mb-6'}>{t('piece.parameters')}</p>

                            <div className="relative p-6 flex-auto">


                                <div id='form-piece'>
                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.name')}</label>
                                        <input type={'text'} value={piece.name} style={{width: "200px"}}
                                               onChange={(e) => setPiece({...piece, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.piece_type')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "200px"}}
                                            value={piece.type?.id}
                                            onChange={(e) => setPiece({...piece, ...{type: {id: e.target.value}}})}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                        >
                                            <option value="null">Aucun</option>
                                            {props.typeList.map(option => (
                                                <option key={option.name} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </ChoiceInput>
                                    </div>

                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}>{t('piece.product_bio')}</label>
                                        <ChoiceInput
                                            style={{width: "200px"}}
                                            value={piece.productBio}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setPiece({...piece, ...{productBio: e.target.value}})}
                                        >

                                            {optionList.map(option => <option key={option.id}
                                                                              className={'force-text-black'}
                                                                              value={option}>{option}</option>)}

                                        </ChoiceInput>
                                    </div>


                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2 text-right'}
                                        >
                                            {t('piece.product_local')}
                                            <span className={'ml-3'}>
                                        <FontAwesomeIcon className="tooltip-icon"
                                                         icon={faCircleInfo}
                                                         data-tooltip-id="tooltip_local_product"
                                        />
                                    </span>
                                        </label>
                                        <Tooltip id={"tooltip_local_product"} multiline={true}
                                                 className="tooltip max-w-xs rounded" textColor={"#fdc96e"}>
                                            {t('piece.tooltip.local_product')}
                                        </Tooltip>
                                        <ChoiceInput
                                            style={{width: "200px"}}
                                            value={piece.productLocal}
                                            className={'force-text-black'}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setPiece({...piece, ...{productLocal: e.target.value}})}
                                        >

                                            {optionList.map(option => <option key={option.id}
                                                                              className={'force-text-black '}
                                                                              value={option}>{option}</option>)}

                                        </ChoiceInput>
                                    </div>

                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.number')}</label>
                                        <input type={'number'} min='0' style={{width: "200px"}}
                                               value={piece.numberOfPieceByRecipe}
                                               onChange={(e) => setPiece({...piece, ...{numberOfPieceByRecipe: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label
                                            className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.weight')}</label>
                                        <input type={'number'} min='0' style={{width: "200px"}}
                                               value={piece.pieceGrammes}
                                               onChange={(e) => setPiece({...piece, ...{pieceGrammes: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label htmlFor=""
                                               className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.know_impact')}</label>
                                        <input type="checkbox"
                                               checked={displayKnowImpact}
                                               onChange={(e) => setDisplayKnowImpact(e.target.checked)}
                                        />
                                    </div>


                                    {displayKnowImpact &&
                                        <>

                                            <div className={`mb-2 ${piece.knownCo2ByKg ? "opacity-30" : ""}`}>
                                                <label
                                                    className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.known_CO2_by_piece')}</label>
                                                <input type={'number'} min='0' style={{width: "200px"}}
                                                       value={piece.knownCo2ByPiece}
                                                       onChange={(e) => setPiece({...piece, ...{knownCo2ByPiece: e.target.value}})}
                                                       disabled={piece.knownCo2ByKg}
                                                />
                                            </div>

                                            <div className={`mb-2 ${piece.knownCo2ByPiece ? "opacity-30" : ""}`}>
                                                <label
                                                    className={'inline-block w-1/2 mr-2  text-right'}>{t('piece.known_CO2_by_kg')}</label>
                                                <input type={'number'} min='0' style={{width: "200px"}}
                                                       value={piece.knownCo2ByKg}
                                                       onChange={(e) => setPiece({...piece, ...{knownCo2ByKg: e.target.value}})}
                                                       disabled={piece.knownCo2ByPiece}
                                                />
                                            </div>
                                        </>
                                    }


                                </div>


                            </div>


                            <div>

                                <AddRecipeCaterer
                                    piece={piece}
                                    setPiece={setPiece}
                                />
                            </div>


                            {/*footer*/}
                            <div
                                className="flex items-center mt-10 justify-center p-6 border-t border-solid border-slate-200 rounded-b">


                                <button
                                    className="text-red-500 font-bold  px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => props.setModalPiece(false)}
                                >
                                    {t('eventList.modal.cancel')}
                                </button>

                                <button
                                    className=" btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                        savePiece()
                                        props.setModalPiece(false)
                                    }}
                                >
                                    {changeLabelButton()}
                                </button>


                            </div>
                        </>}

                    </div>
                </div>
            </div>
        </>
    )

}