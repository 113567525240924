import {createFurniture, updateFurniture} from "../../../utils/api/furniture";
import {getComplementaryColor} from "../../../actions/backgroundColor";
import {ChoiceInput} from "../../shared/input/Input";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function ModalAddFurniture(props) {
    const {user} = useSelector(state => state.security)
    const customization = useSelector(state => state.customization)
    const [t] = useTranslation('common')
    const [furniture, setFurniture] = useState({})

    const saveFurniture = async (furniture) => {
        if (!furniture.name) {
            toast.error(t('furniture.create_error'))
        } else {
            //nouvel objet qui reprend l'objet furniture et vérifie si efRecycled et ef sont définis
            const newFurniture = {
                ...furniture,
                ef: furniture.ef ? furniture.ef : 1,
                // efRecycled: furniture.efRecycled ? furniture.efRecycled : 1
                efRecycled: furniture.ef ? furniture.ef : 1
            };

            //UPDATE
            if (newFurniture.id) {
                await updateFurniture(user.token, newFurniture)
                props.setModalFurniture(false);
                props.setFurniture(prevState => {
                    const updatedFurniture = prevState.map(item => item.id === newFurniture.id ? newFurniture : item);
                    return updatedFurniture;
                });
                toast.success(t('furniture.maj'))
            } else {
                //CREATE
                await createFurniture(user.token, newFurniture)
                props.setModalFurniture(false);

                toast.success(t('furniture.create'))
            }

            props.setReload(Date.now());


        }
    }

    useEffect(() => {
        if (props.selectedFurniture) {

            if (!props.selectedFurniture.unite) {
                props.selectedFurniture.unite = 'kg';
            }

            setFurniture(props.selectedFurniture)
        }
    }, [])


    const generateLabelButtonSave = () => {

        if (furniture.id) {
            return t('ingredients.update');
        } else {
            return t('ingredients.save');

        }

    };

    return (
        <>
            <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
            <div
                className="justify-center items-center flex mt-20 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={() => props.setModalFurniture(false)}
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl" onClick={(e) => {e.preventDefault();e.stopPropagation()}}>
                    {/*content*/}
                    <div
                        style={{backgroundColor: getComplementaryColor('codeHexa', customization.backgroundColor) ?? '#10384e'}}
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        {/*body*/}

                        <h1 className={'blancCasse text-center my-6 text-2xl'}>{t('catalogue.addItemModal.title')}</h1>

                        <div className="relative p-6 flex-auto justify-center flex">
                            <div>
                                <div className={'flex mb-5'}>
                                    <div className={'mr-5'}>
                                        <label className={'inline-block mr-2'}>{t('catalogue.addItemModal.itemName')}</label>
                                        <input type={'text'}
                                               className={'w-72'}
                                               defaultValue={furniture.name}
                                               onChange={(e) => setFurniture({...furniture, ...{name: e.target.value}})}
                                        />
                                    </div>

                                    <div className={'mb-2'}>
                                        <label className={'inline-block mr-2'}>{t('catalogue.addItemModal.unity')}</label>
                                        <ChoiceInput
                                            name="type"
                                            style={{width: "auto"}}
                                            value={furniture.unite}
                                            textColorClass={"select-text-color-black"}
                                            onChange={(e) => setFurniture({...furniture, ...{unite: e.target.value}})}

                                            className={'force-text-black'}>
                                            <option value={'kg'}>{t('ingredients.kg')}</option>
                                            <option value={'m²'}>m²</option>
                                            <option value={'unite'}>{t('ingredients.unite')}</option>
                                            <option value={'ml'}>{t('ingredients.lm')}</option>
                                        </ChoiceInput>
                                    </div>
                                </div>

                                <div className={'mb-5 flex items-center'}>
                                    <label className={'mr-2'} style={{width: '9rem'}}>{t('catalogue.addItemModal.EF')}</label>
                                    <input type={'number'}
                                           name={'ef'}
                                           className={'w-44 mr-5'}
                                           defaultValue={furniture.ef}
                                           min={0}
                                           onChange={(e) => setFurniture({...furniture, ...{ef: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {furniture.unite === 'm²' ? 'm²' : t('ingredients.' + furniture.unite)}</label>
                                </div>

                                {/* <div className={'mb-5 flex items-center'}>
                                    <label className={'mr-2'} style={{width: '9rem'}}>FE recyclé</label>
                                    <input type={'number'}
                                           name={'ef_recycled'}
                                           className={'w-44 mr-5'}
                                           defaultValue={furniture.efRecycled}
                                           min={0}
                                           onChange={(e) => setFurniture({...furniture, ...{efRecycled: e.target.value}})}
                                    />
                                    <label
                                        className={'inline-block mr-2'}>kgCO2e/ {furniture.unite === 'm²' ? 'm²' : t('ingredients.' + furniture.unite)}</label>
                                </div> */}

                            </div>
                        </div>

                        {/*footer*/}
                        <div
                            className="flex items-center justify-center p-6 rounded-b">

                            <button
                                className="text-red-500 font-bold px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                    props.setModalFurniture(false)
                                }}
                            >
                                {t('eventList.modal.cancel')}
                            </button>

                            <button
                                className="btn-create px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    saveFurniture(furniture);
                                }}
                            >
                                {generateLabelButtonSave()}

                            </button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}